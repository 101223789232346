.container-login {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    background-color: var(--white);
    padding: 14px !important;
    border-radius: 4px;
    box-shadow: var(--shadow);
    height: fit-content;
    margin: 0 1rem !important;
}

.main-login {
    display: flex;
    align-items: center;
}

.logo-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px !important;
}

@media (max-width: 750px) {
    .logo-login {
        display: none;
    }
}
